// extracted by mini-css-extract-plugin
export const root = "header-module--root--2Pllp";
export const wrapper = "header-module--wrapper--182n5";
export const branding = "header-module--branding--1PjA2";
export const toggleNavButton = "header-module--toggleNavButton--34Tf4";
export const nav = "header-module--nav--106zH";
export const active = "header-module--active--2DNib";
export const showNav = "header-module--showNav--CuuOZ";
export const close = "header-module--close--3lI6H";
export const link = "header-module--link--3R2_R typography-module--link--1L3_3";
export const mobileNav = "header-module--mobileNav--2Ut8l";
export const mobileNavShown = "header-module--mobileNavShown--3Ep97";
export const mobileHeader = "header-module--mobileHeader--1Z9UU";