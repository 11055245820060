import React from "react";

const strokeStyle = { vectorEffect: "non-scaling-stroke" };

const CloseIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4.36827" y="3.64645" width="23" height="1" transform="rotate(45 4.36827 3.64645)" fill="white" />
        <rect x="3.36827" y="19.6465" width="23" height="1" transform="rotate(-45 3.36827 19.6465)" fill="white" />
    </svg>
);

export default CloseIcon;
