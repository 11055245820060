import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, keywords, title, image }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || (data.site && data.site.description) || "";
        const siteTitle = (data.site && data.site.title) || "";
        const siteAuthor = (data.site && data.site.author && data.site.author.name) || "";
        const metaImage = (image && image) || "";
        return (
          <>
            <Helmet
              htmlAttributes={{ lang }}
              title={title}
              titleTemplate={title === siteTitle ? "%s" : `%s | ${siteTitle}`}
              meta={[
                {
                  name: "description",
                  content: metaDescription
                },
                {
                  property: "og:title",
                  content: title
                },
                {
                  property: "og:description",
                  content: metaDescription
                },
                {
                  property: "og:type",
                  content: "website"
                },
                {
                  property: "og:image",
                  content: metaImage
                },
                {
                  name: "twitter:card",
                  content: "summary"
                },
                {
                  name: "twitter:creator",
                  content: siteAuthor
                },
                {
                  name: "twitter:title",
                  content: title
                },
                {
                  name: "twitter:description",
                  content: metaDescription
                }
              ]
                .concat(
                  keywords && keywords.length > 0
                    ? {
                      name: "keywords",
                      content: keywords.join(", ")
                    }
                    : []
                )
                .concat(meta)}
            />
            <Helmet>
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="manifest" href="/site.webmanifest" />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta name="theme-color" content="#ffffff" />
            </Helmet>
          </>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;

const detailsQuery = graphql`
                      query DefaultSEOQuery {
                        site: sanitySiteSettings(_id: {eq: "siteSettings" }) {
                        title
      description
                      keywords
                      author {
                        name
                      }
    }
  }
                      `;
