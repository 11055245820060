import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import { StaticQuery, graphql } from "gatsby";
import * as styles from "./footer.module.css";

const Footer = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
    <StaticQuery
        query={graphql`
        query FooterQuery {
          site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            copyright
            instagram
            instagramText
            email
            emailText
          }
        }
      `}
        render={(data) => {
            const site = data.site;
            return (
                <div className={styles.root}>
                    <div className={styles.wrapper}>
                        {site.copyright && <div className={styles.copyright}>&copy; {new Date().getFullYear()} &nbsp;{site.copyright}</div>}
                        <nav className={styles.nav}>
                            <ul className={styles.links}>
                                {site.email && site.emailText && <li><a href={`mailto:${site.email}`} title="Email">{site.emailText}</a></li>}
                                {site.instagram && site.instagramText && <li><a href={`${site.instagram}`} target="_blank">{site.instagramText}</a></li>}
                            </ul>
                        </nav>
                    </div>
                    <div className={styles.branding}>
                        <Link to="/"><Icon symbol="logo" /></Link>
                    </div>
                </div>
            )
        }
        } />
)

export default Footer;
