import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn, mapEdgesToNodes } from "../lib/helpers";
import { StaticQuery, graphql } from "gatsby";

import * as styles from "./header.module.css";

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          projects: allSanityProjects {
            edges {
              node {
                projects {
                  _key
                  title
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const projects = data.projects ? mapEdgesToNodes(data.projects) : [];
        const projectNodes = projects[0].projects;
        return (
          <>
            <div className={styles.root}>
              <div className={styles.wrapper}>
                <div className={styles.branding}>
                  <Link to="/">
                    <Icon symbol="logo" />
                  </Link>
                </div>

                <button className={styles.toggleNavButton} onClick={onShowNav}>
                  <Icon symbol="hamburger" />
                </button>

                <nav className={cn(styles.nav, showNav && styles.showNav)}>
                  <ul>
                    <li>
                      <Link to="/projects/" activeClassName={styles.active}>
                        <span className={styles.link}>Projects</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/about/" activeClassName={styles.active}>
                        <span className={styles.link}>About</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className={cn(styles.mobileNav, showNav && styles.mobileNavShown)}>
              <button onClick={onHideNav} className={styles.close} type="button" role="Close Menu">
                <Icon symbol="close" />
              </button>
              <nav>
                <ul>
                  <li>
                    <h3 className={styles.mobileHeader}>Projects</h3>
                    {projectNodes?.map((project) => (
                      <Link key={project._key} to={`/project/${project.slug.current}`}>
                        <span className={styles.link}>{project.title}</span>
                      </Link>
                    ))}
                  </li>
                  <li>
                    <h3 className={styles.mobileHeader}>Information</h3>
                    <Link to="/about/">
                      <span className={styles.link}>About</span>
                    </Link>
                  </li>
                  <li>
                    <h3 className={styles.mobileHeader}>Follow</h3>
                    <a href="mailto:info@kenzodigital.com" className={styles.link}>
                      Email
                    </a>
                    <a
                      href="https://www.instagram.com/kenzodigital/"
                      target="_blank"
                      className={styles.link}
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        );
      }}
    />
  );
};

export default Header;
