import React from "react";

const strokeStyle = { vectorEffect: "non-scaling-stroke" };

const HamburgerIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="5.5" width="23" height="1" fill="white" />
    <rect x="0.5" y="11.5" width="23" height="1" fill="white" />
    <rect x="0.5" y="17.5" width="23" height="1" fill="white" />
  </svg>
);

export default HamburgerIcon;
